import type { Dispatch, SetStateAction } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useWeb3, useWeb3ReadOnly } from '@/hooks/wallets/web3'
import { useCurrentChain } from '@/hooks/useChains'
import useWallet from '@/hooks/wallets/useWallet'
import type { EthersError } from '@/utils/ethers-utils'
import { getInitialCreationStatus } from '@/components/new-safe/create/steps/StatusStep/index'
import type { PendingSafeTx } from '@/components/new-safe/create/types'
import {
  createNewSafe,
  getSafeDeployProps,
  checkSafeCreationTx,
  getSafeCreationTxInfo,
  handleSafeCreationError,
  SAFE_CREATION_ERROR_KEY,
  showSafeCreationError,
  estimateSafeCreationGas,
} from '@/components/new-safe/create/logic'
import { useAppDispatch } from '@/store'
import { closeByGroupKey } from '@/store/notificationsSlice'
import { waitForCreateSafeTx } from '@/services/tx/txMonitor'
import useGasPrice from '@/hooks/useGasPrice'
import { FEATURES, hasFeature } from '@/utils/chains'
import type { DeploySafeProps } from '@safe-global/safe-core-sdk'
import { usePendingSafe } from './usePendingSafe'

export enum SafeCreationStatus {
  AWAITING,
  PROCESSING,
  WALLET_REJECTED,
  ERROR,
  REVERTED,
  TIMEOUT,
  SUCCESS,
  INDEXED,
  INDEX_FAILED,
}

export const useSafeCreation = (
  status: SafeCreationStatus,
  setStatus: Dispatch<SetStateAction<SafeCreationStatus>>,
) => {
  const [isCreating, setIsCreating] = useState(false)
  const [isWatching, setIsWatching] = useState(false)
  const dispatch = useAppDispatch()
  const [pendingSafe, setPendingSafe] = usePendingSafe()

  const wallet = useWallet()
  const provider = useWeb3()
  const web3ReadOnly = useWeb3ReadOnly()
  const chain = useCurrentChain()
  const [gasPrice, , gasPriceLoading] = useGasPrice()

  const maxFeePerGas = gasPrice?.maxFeePerGas
  const maxPriorityFeePerGas = gasPrice?.maxPriorityFeePerGas

  const isEIP1559 = chain && hasFeature(chain, FEATURES.EIP1559)

  const createSafeCallback = useCallback(
    async (txHash: string, tx: PendingSafeTx) => {
      setStatus(SafeCreationStatus.PROCESSING)
      setPendingSafe(pendingSafe ? { ...pendingSafe, txHash, tx } : undefined)
    },
    [setStatus, setPendingSafe, pendingSafe],
  )

  const handleCreateSafe = useCallback(async () => {
    if (!pendingSafe || !provider || !chain || !wallet || isCreating || gasPriceLoading) return

    setIsCreating(true)
    dispatch(closeByGroupKey({ groupKey: SAFE_CREATION_ERROR_KEY }))

    const { owners, threshold, saltNonce } = pendingSafe

    try {
      const tx = await getSafeCreationTxInfo(provider, owners, threshold, saltNonce, chain, wallet)

      const safeParams = {
        threshold,
        owners: owners.map((owner) => owner.address),
        saltNonce,
      }

      const safeDeployProps = getSafeDeployProps(safeParams, (txHash) => createSafeCallback(txHash, tx), chain.chainId)

      const gasLimit = await estimateSafeCreationGas(chain, provider, tx.from, safeParams)

      const options: DeploySafeProps['options'] = isEIP1559
        ? {
            maxFeePerGas: maxFeePerGas?.toString(),
            maxPriorityFeePerGas: maxPriorityFeePerGas?.toString(),
            gasLimit: gasLimit.toString(),
          }
        : { gasPrice: maxFeePerGas?.toString(), gasLimit: gasLimit.toString() }

      await createNewSafe(provider, {
        ...safeDeployProps,
        options,
      })
      setStatus(SafeCreationStatus.SUCCESS)
    } catch (err) {
      const _err = err as EthersError
      const status = handleSafeCreationError(_err)

      setStatus(status)

      if (status !== SafeCreationStatus.SUCCESS) {
        dispatch(showSafeCreationError(_err))
      }
    }

    setIsCreating(false)
  }, [
    chain,
    createSafeCallback,
    dispatch,
    gasPriceLoading,
    isCreating,
    isEIP1559,
    maxFeePerGas,
    maxPriorityFeePerGas,
    pendingSafe,
    provider,
    setStatus,
    wallet,
  ])

  const watchSafeTx = useCallback(async () => {
    if (!pendingSafe?.tx || !pendingSafe?.txHash || !web3ReadOnly || isWatching) return

    setStatus(SafeCreationStatus.PROCESSING)
    setIsWatching(true)

    const txStatus = await checkSafeCreationTx(web3ReadOnly, pendingSafe.tx, pendingSafe.txHash, dispatch)
    setStatus(txStatus)
    setIsWatching(false)
  }, [isWatching, pendingSafe, web3ReadOnly, setStatus, dispatch])

  // Create or monitor Safe creation
  useEffect(() => {
    if (status !== getInitialCreationStatus()) return

    if (pendingSafe?.txHash && !isCreating) {
      void watchSafeTx()
      return
    }

    if (pendingSafe?.taskId && !isCreating) {
      waitForCreateSafeTx(pendingSafe.taskId, setStatus)
      return
    }

    void handleCreateSafe()
  }, [handleCreateSafe, isCreating, pendingSafe?.taskId, pendingSafe?.txHash, setStatus, status, watchSafeTx])

  return {
    handleCreateSafe,
  }
}
